import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"

const BloglistApp = () => {
  const data = useStaticQuery(graphql`
  {
    file( relativePath: {  eq: "bloglist-app/README.md" } ) {
      childMarkdownRemark {
        html
      }
    }
  }
  `)

  const markdownHTML = data.file.childMarkdownRemark.html

  return (    
      <Layout pageTitle="Bloglist App"> 
        <div 
          className="markdownHTML-container"           
          dangerouslySetInnerHTML={{ __html: markdownHTML }}
        />
      </Layout>    
  )
}

export default BloglistApp